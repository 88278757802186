.AccountHeader {
  display: flex;
  flex-direction: row;
}

.AccountHeader.ui.header.large {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
    var(--account-header-background);
  min-height: 176px;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.AccountHeader .avatar-face {
  flex: 0 0 auto;
}

.AccountHeader .profile-text {
  color: white;
  padding-left: 27px;
  display: flex;
  flex-direction: column;
}

.AccountHeader .profile-text .profile-name {
  line-height: 48px;
  font-size: 40px;
  font-weight: 700;
}

.AccountHeader .profile-text .profile-description {
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  align-items: center;
  word-break: break-all;
  cursor: pointer;
}

.AccountHeader
  .profile-text
  .profile-description:hover
  .profile-description-edit-icon {
  visibility: visible;
}

.AccountHeader
  .profile-text
  .profile-description
  .profile-description-edit-icon {
  visibility: hidden;
  margin-left: 15px;
  margin-right: 24px;
}

.AccountHeader .actions {
  flex: 1 0 auto;
  text-align: right;
}

.AccountHeader .ui.button.inverted.primary {
  color: white;
  border-color: white;
}
