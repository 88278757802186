.ui.modal.ConvertManaModal {
  padding: 26px;
  width: 520px;
}

.ConvertManaModal .title {
  text-align: center;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.3px;
}

.ConvertManaModal .subtitle {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;

  /* identical to box height, or 153% */
  text-align: center;
  letter-spacing: -0.2px;
}

.ConvertManaModal .usd-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  text-align: center;
  color: #736e7d;
}

.ConvertManaModal .amount-error {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  text-align: center;
  color: red;
}

.ConvertManaModal .dcl.close,
.ui.modal > .dcl.close {
  margin: 10px;
  padding: 10px;
  background-color: white;
}

.ConvertManaModal .ui.sub.header {
  text-transform: none;
  font-size: 15px;
  line-height: 24px;
}

.ConvertManaModal .price {
  padding-top: 10px;
  text-align: center;
}

.ConvertManaModal .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConvertManaModal .dcl.field {
  width: 100%;
  min-width: 100%;
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.ConvertManaModal .dcl.field .ui.input.amount input,
.ConvertManaModal .ui.form .dcl.field .ui.input.amount input {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

.ConvertManaModal .dcl.field .ui.input.wallet input,
.ConvertManaModal .ui.form .dcl.field .ui.input.wallet input {
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}

.ConvertManaModal button.ui.primary.start-transaction-button {
  margin-top: 20px;
  width: 100%;
}

.ConvertManaModal .dcl.field .ui.basic.button {
  padding-bottom: 40px;
}

.ConvertManaModal .fees-warning {
  min-height: 20px;
  padding: 10px;
  border-radius: 8px;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  text-align: center;
  color: #736E7D;
}
