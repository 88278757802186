.AccountTransaction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 20px;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
}

.AccountTransaction:hover {
  cursor: pointer;
  opacity: 0.7;
}

.AccountTransaction .DescriptionStatus {
  padding: 0px 0px;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
}

.AccountTransaction .DescriptionStatus .status {
  font-size: 15px;
  line-height: 24px;
  color: #736e7d;
}

.AccountTransaction .DescriptionStatus,
.AccountTransaction .amount,
.AccountTransaction .type {
  margin-right: 20px;
}

.AccountTransaction .amount {
  font-weight: 600;
}

.transaction-logo {
  width: 34px;
  height: 34px;
}

.AccountTransaction .in-transaction-logo {
  background-image: url('../../../../../images/in-transaction.svg');
}

.AccountTransaction .out-transaction-logo {
  background-image: url('../../../../../images/out-transaction.svg');
}

.AccountTransaction .out-pending-transaction-logo {
  background-image: url('../../../../../images/out-pending-transaction.svg');
}

.AccountTransaction .in-pending-transaction-logo {
  background-image: url('../../../../../images/in-pending-transaction.svg');
}

.AccountTransaction .withdrawal-checkpoint {
  color: #069f5e;
  font-weight: 600;
}
