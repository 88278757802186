.WithdrawalStatusModal {
  padding: 48px 80px;
}

.WithdrawalStatusModal .ui.modal {
  width: 520px;
  height: 518px;
}

.WithdrawalStatusModal .header {
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #16141A;
}

.WithdrawalStatusModal .amount_placeholder, .WithdrawalStatusModal .status_placeholder {
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #736E7D;
}

.WithdrawalStatusModal .amount {
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.315px;
  color: #16141A;
  padding-bottom: 40px;
}

.WithdrawalStatusModal .status {
  display: flex;
  flex-direction: column;
}

.WithdrawalStatusModal .default_cursor.ui.checkbox,
.WithdrawalStatusModal .default_cursor.ui.checkbox input.hidden + label, 
.WithdrawalStatusModal .default_cursor.ui.checkbox input.hidden:focus + label, 
.WithdrawalStatusModal .default_cursor.ui.checkbox input.hidden:active + label {
  cursor: default;
}

.ui.checkbox input.hidden + label, 
.ui.checkbox input.hidden:focus + label, 
.ui.checkbox input.hidden:active + label {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #16141A;
  margin: 8px 0px;
  padding: 0px 0px 0px 34px
}

.WithdrawalStatusModal .ui.button.primary {
  margin-top: 48px;
  width: 100%;
}
