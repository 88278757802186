.Tooltip {
  position: fixed;
  width: 420px;
  min-height: 206px;
  right: 16px;
  bottom: 16px;
  background: #16141a;
  border-radius: 12px;
  z-index: 3001;
}

.Tooltip.tooltip-hidden {
  display: none;
}

.Tooltip .tooltip-content {
  margin: 24px;
}

.Tooltip .tooltip-content > p {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #ffffff;
}

.Tooltip .tooltip-content > h1 {
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #ffffff;
}

.Tooltip .tooltip-content > h1 {
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #ffffff;
}

.Tooltip .ui.button {
  margin-left: -8px;
}
