@font-face {
  font-family: 'dogica';
  src: url('./themes/font/dogica.ttf');
}

@font-face {
  font-family: 'robot-crush';
  src: url('./themes/font/robot-crush.ttf');
}

:root {
  --account-header-background: #b7a5ff;
  --background: #150a24;
  --background-secondary: #130919e5;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
