.ui.inverted.primary.button:focus {
  color: var(--primary);
  background-color: transparent;
}

.ui.loading.button:after {
  border-color: var(--primary) transparent transparent;
}

.ui.button + .ui.button {
  margin-left: 16px;
}
