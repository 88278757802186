.AccountCardHeader {
  padding: 22px;
  border-bottom: 1px solid #ccc;
}

.AccountCardHeader .title {
  font-size: 21px;
  line-height: 28px;
  margin-bottom: 10px;
}

.AccountCardHeader .operation-menu {
  font-size: 24px;
  float: right;
  margin-top: -35px;
}

.AccountCardHeader .fundsContainer {
  display: flex;
  flex-direction: row;
}

.AccountCardHeader .funds {
  flex-grow: 1;
}

.AccountCardHeader .funds .amount {
  display: flex;
  justify-content: row;
  align-items: flex-start;
  align-items: center;
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
}

.AccountCardHeader .funds .amount :first-child {
  margin-right: 10px;
}

.AccountCardHeader .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.AccountCardHeader .actions .ui.button {
  padding: 0px;
  font-size: 13px;
  height: 44px;
  min-width: 96px;
  border-radius: 8px;
}
.AccountCardHeader i.icon.dropdown {
  display: none;
}

.AccountCardHeader .add-mana-icon {
  background-image: url(../../../../images/seed.png);
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 4px;
}

.AccountCardHeader .title-text-container {
  display: flex;
}

.AccountCardHeader:hover .info-logo {
  opacity: 1;
}

.AccountCardHeader .info-logo {
  opacity: 0;
  cursor: pointer;
  margin: 3px;
  width: 24px;
  height: 24px;
  background-image: url('../../../../images/info.svg');
}
